import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { Form, FieldTextInput, PrimaryButton, Page, LayoutSingleColumn } from '../../components';
import css from '../../components/Button/Button.module.css';
import axios from 'axios';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';

const PASSWORD = process.env.REACT_APP_UPLOAD_FILE_PASSWORD;
const ACCEPT_FILES = '.xls,.xlsx';

const UploadDataComponent = props => (
  <FinalForm
    {...props}
    onSubmit={async (values, form) => {
      const { password, file } = values;
      if (password !== PASSWORD) {
        setError(true);
      } else {
        try {
          const url = `${process.env.REACT_APP_MARKETPLACE_BACKEND_ROOT_URL}/api/admin-create-new-listings`;
          let formData = new FormData();
          formData.append('file', file);
          const response = await axios.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          });
          alert('After Completion you will receive the mail on -> admin@kalinoi.com');
          if (response.status === 200) {
            form.change('data', response);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        intl,
        handleSubmit,
        invalid,
        form,
        values,
      } = fieldRenderProps;
      const [error, setError] = useState(false);
      const [data, setData] = useState(false);

      useEffect(() => {
        setData(values?.data?.data?.data);
      }, [values?.data]);

      const PasswordMatch = intl.formatMessage({
        id: 'FileUpload.passwordMatch',
      });

      // password
      const passwordLabel = intl.formatMessage({
        id: 'FileUpload.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'FileUpload.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'FileUpload.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const buttonClasses = classNames(rootClassName || css.primaryButtonRoot, css.primaryButton);

      const submitDisabled = invalid;

      return (
        <Page title={'Kalinoi'} scrollingDisabled={false}>
          <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterComponent />}>
            <Form onSubmit={handleSubmit}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '90%',
                  maxWidth: '500px',
                  margin: '0 auto',
                }}
              >
                <Field accept={ACCEPT_FILES} id={'file'} name={'file'} type="file" form={null}>
                  {fieldProps => {
                    const { accept, id, input, disabled } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      console.log(file, '^^^  ^^^ => file');

                      if (file) {
                        form.change(`file`, file);
                        form.blur(`file`);
                      }
                    };

                    return (
                      <div>
                        <div>
                          <label>File</label>
                          <input
                            accept={accept}
                            id={id}
                            name={name}
                            disabled={disabled}
                            onChange={onChange}
                            type={type}
                          />
                        </div>
                      </div>
                    );
                  }}
                </Field>

                <FieldTextInput
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="current-password"
                  label={passwordLabel}
                  onKeyUp={() => setError(false)}
                  placeholder={passwordPlaceholder}
                  validate={passwordRequired}
                />
                {error && PasswordMatch}
                <div>
                  <PrimaryButton className={buttonClasses} type="submit" disabled={submitDisabled}>
                    <FormattedMessage id="FileUpload.upload" />
                  </PrimaryButton>
                </div>
              </div>
              {data && (
                <table style={{ margin: '10px auto' }} cellPadding={10}>
                  <tr key={'data-response'}>
                    <th>Email</th>
                    <th>User</th>
                    <th>Listing</th>
                  </tr>
                  {data?.map((item, index) => {
                    const email = Object?.keys(item)[0];
                    return (
                      <tr key={index}>
                        <td>{email}</td>
                        <td>{item[email]?.user?.message}</td>
                        <td>{item[email]?.listing?.message}</td>
                      </tr>
                    );
                  })}
                </table>
              )}
            </Form>
          </LayoutSingleColumn>
        </Page>
      );
    }}
  />
);

UploadDataComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
};

UploadDataComponent.propTypes = {
  rootClassName: String,
  className: String,
  form: String,
  intl: intlShape.isRequired,
};

const UploadData = compose(injectIntl)(UploadDataComponent);
UploadData.displayName = 'UploadData';

export default UploadData;
